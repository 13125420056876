import { DateTime } from "luxon";
import { FeedbackType } from "../../interfaces/feedback.js";
const getCommonProps = (feedback) => ({
  type: feedback.type.toLowerCase(),
  feedbackId: feedback.id,
  user: {
    id: feedback.user?.id || "",
    username: feedback.user?.name || "",
    passState: false,
    hasChargedWithPass: feedback.isChargeWithPass
  },
  isAnonymous: !feedback.user,
  creationDate: feedback.creationDate ? DateTime.fromISO(feedback.creationDate) : void 0,
  rating: {
    globalScore: feedback.rating?.globalNote || 0,
    locationScore: feedback.rating?.locationNote || 0,
    materialScore: feedback.rating?.materialNote || 0,
    priceScore: feedback.rating?.priceNote || 0,
    securityScore: feedback.rating?.securityNote || 0
  },
  mustDisplayWholeComment: false,
  locale: feedback.locale,
  response: {
    content: feedback.chargemapResponse?.publicContent,
    name: "Chargemap",
    creationDate: feedback.chargemapResponse && feedback.chargemapResponse.creationDate ? DateTime.fromISO(feedback.chargemapResponse.creationDate) : void 0
  },
  responseSecondary: {
    content: feedback.partnerResponse?.content || "",
    name: feedback.partnerResponse?.name || "",
    creationDate: feedback.partnerResponse && feedback.partnerResponse.creationDate ? DateTime.fromISO(feedback.partnerResponse.creationDate) : void 0
  },
  hasBeenRejected: false,
  hasAlreadyEditedComment: false
});
const getCheckinProps = (feedback) => {
  if (feedback.type.toLowerCase() === FeedbackType.CHECKIN) {
    return {
      chargeStartDate: feedback.chargeStartDate ? DateTime.fromISO(feedback.chargeStartDate) : void 0,
      chargeEndDate: feedback.chargeEndDate ? DateTime.fromISO(feedback.chargeEndDate) : void 0,
      connector: {
        type: feedback.connectorType,
        maxPower: feedback.connectorMaxPower
      },
      vehicle: {
        brandName: feedback.vehicleBrandName,
        modelName: feedback.vehicleModelName,
        versionName: feedback.vehicleVersionName
      },
      comment: feedback.comment
    };
  }
  return {};
};
const getCommentProps = (feedback) => {
  if (feedback.type.toLowerCase() === FeedbackType.COMMENT) {
    return {
      content: feedback.comment
    };
  }
  return {};
};
const getReportProps = (feedback) => {
  if (feedback.type.toLowerCase() === FeedbackType.REPORT) {
    return {
      reasonType: feedback.reasonType,
      isOperatorWarned: !!feedback.isOperatorWarned,
      comment: feedback.comment
    };
  }
  return {};
};
const getFeedbackType = (feedback) => feedback.type.toLowerCase();
const getFeedbackProps = (feedback) => {
  if (feedback) {
    return {
      feedback,
      feedbackType: getFeedbackType(feedback),
      commonProps: getCommonProps(feedback),
      checkinProps: getCheckinProps(feedback),
      commentProps: getCommentProps(feedback),
      reportProps: getReportProps(feedback)
    };
  }
  return {
    feedback: null,
    feedbackType: "",
    commonProps: null,
    checkinProps: null,
    commentProps: null,
    reportProps: null
  };
};
export {
  getCommonProps,
  getCheckinProps,
  getCommentProps,
  getReportProps,
  getFeedbackType,
  getFeedbackProps
};
